@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@200;300;400;500;600;700&family=Rokkitt:wght@400;500;600;700;800;900&family=Sansita:ital,wght@0,900;1,900&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  h1 {
    font-family: "Arial";
    font-weight: 700;
    /* font-size: xx-large; */
  }
}

.active {
  @apply text-pink-first text-xl duration-300 text-transparent bg-clip-text bg-gradient-to-r from-pink-first  to-pink-second;
}

.sticky {
  @apply fixed top-0 w-full;
}

@layer utilities {
  .square {
    aspect-ratio: 1/1;
  }
}
